$font-stack: Roboto, "Droid Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;

$primary-color: #394694;
$primary-dull: #858585;
$secondary-color: #5569A4;
$tertiary-color: #97A5D6;
$off-white: #e8e8e8;
$table-text-color: #5A5A5A;
$disabled-color: #C0C0C0;
$light-gray: #E5E5E5;
$not-so-black-black: #282828;
$contrast-blue: #342E56;
$invisible: rgba(0, 0, 0, 0);

$add-to-cart-green : #66c01a;


$material-valid: #0DC691;
$material-invalid : #c6575c;


#hhhhhh {
  color: $off-white;
  background-color: $add-to-cart-green;


  > #hasjdkasdj{
    display:flex;
    flex-direction:column;
  }

}