//

@import "bourbon/bourbon";
@import "frameshop/breakpoints";
@import "colours";

@mixin RobotoSlim{
  font-family: Roboto, "Droid Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 300;
}


.text-sleek{
  @include RobotoSlim();

  h1,h2,h3,h4,h5,h6{
    font-weight: 300;
    color:$primary-color;
  }
}

#body-wrapper{
  margin-bottom: 15px;
}